.top-header {
  background-color: rgba(255, 255, 255, 0.1);
  flex: 0 0 auto;
  font-size: 0.75rem;
  display: flex;
  border-bottom: 1px solid #000; }

.menu {
  display: flex; }
  .menu__sub.menu__sub--visible .menu__label {
    background-color: rgba(255, 255, 255, 0.1); }
  .menu__sub.menu__sub--visible .menu__backdrop {
    display: block; }
  .menu__sub.menu__sub--visible .menu__dropdown {
    opacity: 1;
    pointer-events: all; }
  .menu__label {
    padding: 0.125rem 0.5rem;
    text-transform: capitalize;
    border: 0;
    margin: 0;
    background: none;
    font-size: 0.75rem;
    border-radius: 0;
    color: #aaa; }
    .menu__label:hover {
      color: #fff; }
    .menu__label:focus {
      outline: 0; }
  .menu__backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9;
    display: none;
    left: 0; }
  .menu__dropdown {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    background-color: #232323;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    z-index: 10; }
    .menu__dropdown-item {
      display: flex;
      background-color: rgba(255, 255, 255, 0.2);
      border: 0;
      text-align: left;
      font-size: 0.75rem;
      min-width: 12rem;
      padding: 0.125rem 0.5rem;
      align-items: center; }
      .menu__dropdown-item:hover, .menu__dropdown-item:focus {
        outline: 0;
        background-color: rgba(0, 0, 0, 0.05);
        color: #eee; }
  .menu .material-icons {
    font-size: 14px;
    color: #fff; }
