html, #root, body {
  height: 100%;
  overflow: hidden;
  margin: 0; }

body, button, input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-smooth: always; }

.sr-only {
  position: absolute;
  opacity: 0;
  pointer-events: none; }

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: #aaa; }
  button:hover, button:focus {
    outline: none; }

.flex-row {
  display: flex;
  margin: 0.5rem 0; }
  .flex-row:first-child {
    margin-top: 0; }
  .flex-row:last-child {
    margin-bottom: 0; }
  .flex-row > * {
    box-sizing: border-box;
    margin: 0 0.25rem;
    align-self: center; }
    .flex-row > *:first-child {
      margin-left: 0; }
    .flex-row > *:last-child {
      margin-right: 0; }

.flex-fixed {
  flex: 0 0 auto; }

.flex-loose {
  flex: 1 1 auto; }

.custom_icons.selection {
  width: 14px;
  height: 16px;
  display: block;
  background-size: cover;
  background-position: center center;
  background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2024%2024%22%20version%3D%221.1%22%20width%3D%2250px%22%20height%3D%2250px%22%3E%0A%3Cg%20id%3D%22surface1%22%3E%0A%3Cpath%20fill%3D%22white%22%20style%3D%22%20%22%20d%3D%22M%207%202%20L%2019%2013.199219%20L%2013.199219%2013.699219%20L%2016.5%2021%20L%2014.300781%2022%20L%2011.101563%2014.601563%20L%207%2018.5%20L%207%202%20%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A); }

.button:focus {
  outline: none; }

.button-icon {
  background: none;
  border: none;
  color: #fff;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  cursor: pointer; }

.text-left {
  text-align: left !important; }

.js-start {
  justify-self: start; }

.invisible {
  opacity: 0; }

input {
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em; }
  input[type="text"], input[type="number"] {
    display: block;
    width: 100%;
    border: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.25rem;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.9);
    box-sizing: border-box; }
    input[type="text"]:disabled, input[type="number"]:disabled {
      opacity: 0.5; }
