.input-color {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2px; }
  .input-color.disabled {
    opacity: 0.5; }
  .input-color__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 1; }
    .input-color__backdrop.visible {
      display: block; }
  .input-color__swatch-wrap {
    width: 100%;
    height: 20px;
    border-radius: 2px;
    overflow: hidden;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQffCh8PKzLYjbDmAAAAHWlUWHRDb21tZW50AAAAAABDcmVhdGVkIHdpdGggR0lNUGQuZQcAAAA9SURBVDhPY/wPBAxEgDNnzkBZ+AETlKYaGDWQcjBqIOWA8fTp00TlFBMTEygLPxiNFMrBqIGUAyobyMAAAObqCoP0uRPJAAAAAElFTkSuQmCC); }
  .input-color__swatch {
    width: 100%;
    height: 20px;
    border-radius: 2px; }
  .input-color .chrome-picker {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
    margin-left: -230px;
    margin-top: -21px; }
    .input-color .chrome-picker.visible {
      opacity: 1;
      pointer-events: all; }
