.nfo {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000; }
  .nfo.visible {
    opacity: 1;
    pointer-events: all; }
  .nfo__backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }
  .nfo__layer {
    background-color: #fff;
    width: 50vw;
    height: 15rem;
    padding: 3rem;
    left: 25vw;
    top: 10vh;
    position: fixed; }
