.toolbar {
  width: 28px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  padding: 2px; }
  .toolbar__button {
    width: 28px;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 2px;
    height: 28px;
    color: rgba(255, 255, 255, 0.5); }
    .toolbar__button.active {
      background-color: rgba(0, 0, 0, 0.8); }
      .toolbar__button.active:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8); }
    .toolbar__button:hover {
      background-color: rgba(255, 255, 255, 0.25);
      color: #fff; }
    .toolbar__button:focus {
      outline: none; }
