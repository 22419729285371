.sidebar {
  font-size: 0.75rem;
  color: #aaa;
  position: relative;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.1); }
  .sidebar__gap {
    height: 4px;
    border-bottom: 1px solid #000; }
  .sidebar__tabs {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex; }
  .sidebar__tab {
    background-color: rgba(255, 255, 255, 0.05);
    color: #aaa;
    border: none;
    display: flex;
    align-items: center;
    padding: 2px 8px; }
    .sidebar__tab.active {
      background-color: rgba(255, 255, 255, 0.12);
      color: #eee; }
  .sidebar__sub {
    width: 100%;
    height: 100%;
    overflow-y: auto; }
  .sidebar.geek-mode .sidebar-easy {
    display: none; }
  .sidebar.geek-mode .sidebar-geek {
    display: block; }
  .sidebar .sidebar-easy {
    display: block; }
  .sidebar .sidebar-geek {
    display: none; }
  .sidebar .material-icons {
    font-size: 0.875rem; }
  .sidebar h3 {
    margin: 0;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #ccc;
    display: flex;
    align-items: center; }
    .sidebar h3 .tip {
      font-weight: normal;
      font-size: 0.675rem;
      color: #aaa;
      margin-left: 8px; }
    .sidebar h3 + div {
      margin-top: 0.5rem; }
  .sidebar label {
    min-width: 1rem;
    font-size: 0.625rem;
    text-align: center; }
  .sidebar__group {
    border-bottom: 1px solid #000;
    padding: 0.5rem; }
    .sidebar__group.hidden {
      display: none; }
    .sidebar__group .collapse {
      margin-left: 8px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.5);
      width: 16px;
      height: 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .sidebar__group .du {
      position: relative;
      opacity: 1;
      pointer-events: all; }
    .sidebar__group .dd {
      position: absolute;
      opacity: 0;
      pointer-events: none; }
    .sidebar__group.collapsed .filters {
      display: none; }
    .sidebar__group.collapsed .du {
      position: absolute;
      opacity: 0;
      pointer-events: none; }
    .sidebar__group.collapsed .dd {
      position: relative;
      opacity: 1;
      pointer-events: all; }

.geek-mode-switch {
  background: #000;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 0;
  margin: 0 0 0 8px;
  font-size: 12px !important;
  cursor: pointer;
  opacity: 0.60;
  display: flex;
  justify-content: center;
  align-items: center; }
  .geek-mode-switch:focus, .geek-mode-switch:hover {
    opacity: 1;
    outline: 0; }

.geek-mode .geek-mode-switch {
  opacity: 1;
  text-shadow: 0px 0px 3px white; }

.sidebar__grid {
  display: grid;
  grid-template-columns: 18px 51px 18px 51px 18px 51px;
  grid-template-rows: 20px;
  grid-column-gap: 5px;
  grid-row-gap: 0.5rem;
  justify-items: center;
  align-items: center; }
  .sidebar__grid .span-2 {
    grid-column-start: span 2; }
  .sidebar__grid .span-3 {
    grid-column-start: span 3; }
  .sidebar__grid .span-4 {
    grid-column-start: span 4; }
  .sidebar__grid .span-5 {
    grid-column-start: span 5; }
  .sidebar__grid .span-6 {
    grid-column-start: span 6; }
