.leftbar {
  font-size: 0.75rem;
  color: #aaa;
  position: relative;
  width: 150px;
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 1px solid #000;
  flex: 0 0 auto;
  padding: 0.25rem;
  box-sizing: border-box; }

.tree-element {
  padding: 0 0 0 10px;
  margin: 0; }

.tree-button {
  margin: 0.25rem 0;
  width: 100%;
  text-align: left;
  position: relative;
  padding: 0; }

.drop-zone {
  height: 4px;
  background-color: black;
  display: block;
  pointer-events: none;
  position: absolute;
  width: 100%;
  opacity: 0; }
  .drop-zone:first-of-type {
    top: -6px; }
  .drop-zone:last-of-type {
    bottom: -6px; }

.drag .drop-zone {
  pointer-events: all; }
  .drag .drop-zone.hover {
    opacity: 1; }
