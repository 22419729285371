.toolbar__button {
  padding: 0; }
  .toolbar__button > * {
    margin: 0 auto; }
  .toolbar__button .material-icons {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -1px; }
