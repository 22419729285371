.box .element--root * {
  pointer-events: none; }

.mouse-down .element:not(.element.selected) .element__tools {
  pointer-events: none;
  opacity: 0; }

.element--root > .element__contents > .element > .element__contents > .element .element__tools {
  opacity: 0 !important;
  pointer-events: none !important; }

.element__contents {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }

.element__tools {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all; }

.element__tool {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  z-index: 2;
  opacity: 0; }
  .element__tool--top-left {
    top: -5px;
    left: -5px; }
  .element__tool--top {
    top: -5px;
    left: calc(50% - 5px); }
  .element__tool--top-right {
    top: -5px;
    right: -5px; }
  .element__tool--left {
    top: calc(50% - 5px);
    left: -5px; }
  .element__tool--center {
    z-index: 1;
    background: none;
    border: none;
    width: 100%;
    height: 100%; }
  .element__tool--right {
    top: calc(50% - 5px);
    right: -5px; }
  .element__tool--bottom-left {
    bottom: -5px;
    left: -5px; }
  .element__tool--bottom {
    bottom: -5px;
    left: calc(50% - 5px); }
  .element__tool--bottom-right {
    bottom: -5px;
    right: -5px; }
  .element__tool--top-left, .element__tool--bottom-right {
    cursor: nwse-resize; }
  .element__tool--top, .element__tool--bottom {
    cursor: ns-resize; }
  .element__tool--top-right, .element__tool--bottom-left {
    cursor: nesw-resize; }
  .element__tool--left, .element__tool--right {
    cursor: ew-resize; }
  .element__tool:not(.element__tool--center) {
    pointer-events: none; }

.element.selected-multiple .element__tool {
  border: 2px solid rgba(0, 0, 255, 0.3); }
  .element.selected-multiple .element__tool:not(.element__tool--center) {
    opacity: 0 !important;
    pointer-events: none !important; }

.element.selected .element__tools {
  width: 100%;
  height: 100%; }

.element.selected .element__tool {
  opacity: 1;
  pointer-events: all !important; }
